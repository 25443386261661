.meta-traffic-light--inline {
  max-width: 322px;
}

.meta-traffic-light__level {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  &--administrativeComplexityLow,
  &--tradeDistortionGreen,
  &--budgetLow,
  &--low {
    & .meta-traffic-light__level-bar:first-child {
      background-color: #34c759;
    }
  }
  &--administrativeComplexityMedium,
  &--tradeDistortionAmber,
  &--budgetMedium,
  &--medium {
    & .meta-traffic-light__level-bar:nth-child(-n + 2) {
      background-color: #ff9500;
    }
  }
  &--administrativeComplexityHigh,
  &--budgetHigh,
  &--high {
    & .meta-traffic-light__level-bar:nth-child(-n + 3) {
      background-color: #ff3b30;
    }
  }
  &--tradeDistortionBlue {
    & .meta-traffic-light__level-bar:nth-child(-n + 3) {
      background-color: #0000ff;
    }
  }
  @media print {
    display: none;
  }
}

.meta-traffic-light--inline .meta-traffic-light__level {
  display: inline-flex;
  margin-bottom: 0;
  vertical-align: middle;
  width: 210px;
}

.meta-traffic-light__level-bar {
  background-color: #efefef;
  border-radius: 0.25em;
  height: 4px;
  width: 32%;
}

.meta-traffic-light__title {
  color: #8c8c8c;
  @include font-size(ms);
  line-height: 1;
}

.meta-traffic-light--inline .meta-traffic-light__title {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}
