.instrument-list {
  text-align: left;
  width: 100%;
}

.instrument-list__title {
  border-bottom: 1px solid #e4e4e4;
  font-size: 21px;
  font-weight: 600;
  line-height: 18px;
  line-height: 1;
  margin-bottom: 30px;
  padding-bottom: 20px;
  width: 100%;
  @include breakpoint(s) {
  }
  @include breakpoint(m) {
  }
}

.instrument-list__result-count {
  color: #808080;
  font-size: 15px;
  font-weight: 500;
}

.instrument-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.instrument-list__instrument {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.instrument-list__instrument-kicker {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 5px;
}

.instrument-list__instrument-kicker::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBIOS4zMzMzM1Y3LjMzMzMzSDBWMFpNMTEuMzMzMyAxMEgxMlYyLjY2NjY3SDEwLjY2NjdWOC42NjY2N0gyVjEwSDEwLjY2NjdIMTEuMzMzM1oiIGZpbGw9IiMxMTExMTEiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 12px;

  @media print {
    display: none;
  }
}

.instrument-list__instrument-title {

  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;

  @include breakpoint(s) {
    font-size: 26px;
    letter-spacing: -0.01em;
    line-height: 30px;
    margin-bottom: 25px;
  }
  @include breakpoint(m) {
    font-size: 30px;
    letter-spacing: -0.01em;
    line-height: 30px;
    margin-bottom: 25px;
  }
}

.instrument-list__instrument-title:last-child {
  margin-bottom: 0;
}

.instrument-list__meta {
  background-color: red;
}
