.cookie-consent-checkbox {
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cookie-consent-checkbox input[type="checkbox"] {
  display: none;
}

.cookie-consent-checkbox label {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
  user-select: none;
  width: 90%;
  font-weight: bold;
  line-height: 1.4;
}

.cookie-consent-checkbox input[type="checkbox"] + label::before {
  border: 1px solid #000;
  border-radius: 0.2em;
  color: transparent;
  content: "";
  display: inline-block;
  height: 1.3em;
  left: 0;
  line-height: 0.8;
  padding-bottom: 0.3em;
  padding-left: 0.2em;
  position: absolute;
  top: 1px;
  transform: translateY(-1px);
  transition: 0.2s;
  vertical-align: middle;
  width: 1.3em;
}

.cookie-consent-checkbox input[type="checkbox"] + label::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNzA3MDYgMi41NDgyNkwwLjI5Mjg0NyAzLjk2MjQ3TDUuMTMxMiA4LjgwMDgzTDEyLjIyNSAxLjcwNzA2TDEwLjgxMDggMC4yOTI4NDdMNS4xMzEyIDUuOTcyNEwxLjcwNzA2IDIuNTQ4MjZaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==);
  content: "";
  height: 9px;
  left: 3px;
  position: absolute;
  top: 6px;
  width: 12px;
}

.cookie-consent-checkbox input[type="checkbox"] + label:active::before {
  transform: scale(0);
}

.cookie-consent-checkbox input[type="checkbox"]:checked + label::before {
  background-color: #4670ff;
  border-color: #4670ff;
  color: #fff;
}

.cookie-consent-checkbox input[type="checkbox"]:disabled + label::before {
  border-color: #aaa;
  transform: scale(1);
}

.cookie-consent-checkbox
  input[type="checkbox"]:checked:disabled
  + label::before {
  background-color: #bfb;
  border-color: #bfb;
  transform: scale(1);
}
