@charset "utf-8";

/* Flickity */
@import "frameworks/flickity";

/* Foundation */
@import "foundation/normalize";
@import "foundation/constants";
@import "foundation/functions";
@import "foundation/type";
@import "foundation/box-sizing";
@import "foundation/base";
@import "foundation/typography";
@import "foundation/section";
@import "foundation/utilities";

/* Sections */
@import "sections/instrument";
@import "sections/instruments";
@import "sections/navigation";
@import "sections/objectives";
@import "sections/text";

/* Pages */
@import "pages/home";
@import "pages/profile";

/* Components */
@import "components/headline";
@import "components/body-text";
@import "components/card";
@import "components/cookiebar";
@import "components/box";
@import "components/breadcrumbs";
@import "components/dimensions";
@import "components/button";
@import "components/cookie-consent-checkbox";
@import "components/policy-dimension-header";
@import "components/instrument-filters";
@import "components/instrument-search";
@import "components/instrument-list";
@import "components/instrument-cards";
@import "components/instrument-meta-information";
@import "components/masthead";
@import "components/objective-list";
@import "components/category-icon";
@import "components/logos";
@import "components/spacer-headline";
@import "components/footer";
@import "components/overlay";
@import "components/policy-dimensions-list";
@import "components/centered-text";
@import "components/meta-list";
@import "components/meta-tags";
@import "components/meta-traffic-light";
@import "components/page-list";
@import "components/verify";
@import "components/search-overlay";

/* Blocks */
@import "blocks/page-cta";

body,
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-align: center;
  -webkit-font-smooting: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.filter h3 {
  cursor: pointer;
  user-select: none;
}

.honey {
  position: absolute;
  left: -9999px;
}
