.meta-tags ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.meta-tags li {
  margin-bottom: 0.75em;
  color: #8C8C8C;
}

.meta-tags li:last-child {
  margin-bottom: 0;
}

.meta-tags__item {
  background-color: #efefef;
  border-radius: 2em;
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  padding: 4px 12px;
}

@media print {
  .meta-tags__item {
    background-color: #fff;
    border: 1px solid #000;
  }
}

.meta-tags__implementation-level {
  padding: 4px 12px 4px 8px;
}

.meta-tags__implementation-level::before {
  background-position: center;
  content: "";
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.meta-tags__implementation-level--nationalgovernment::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjQuNzUiIHN0cm9rZT0iIzQ2NzBGRiIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjYuNzUiIHN0cm9rZT0iIzQ2NzBGRiIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGw9IiM0NjcwRkYiLz4KPC9zdmc+Cg==");
  height: 14px;
  margin-top: -2px;
  width: 14px;
}

.meta-tags__implementation-level--competentauthority::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjQuNzUiIHN0cm9rZT0iIzQ2NzBGRiIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGw9IiM0NjcwRkYiLz4KPC9zdmc+Cg==");
  height: 10px;
  margin-top: -2px;
  width: 10px;
}

.meta-tags__implementation-level--onsite::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGw9IiM0NjcwRkYiLz4KPC9zdmc+Cg==");
  margin-top: -2px;
  height: 6px;
  width: 6px;
}
