

.verify-badge {
  display: inline-flex;
  align-items: center;
  padding: .25rem;
  color: #4670ff;
  background-color: #E3EAFE;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1;
  padding: .5rem;
  white-space: nowrap;
  border-radius: 6px;

  svg {
    margin-right: .25rem;
  }
}
.verify-title {
  margin-right: .75rem;
}

.verify-by {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media print {
    display: none;
  }
}

.verify-by__verified {
  display: flex;
}

.verify-by__text {
  display: flex;
  align-items: center;
  color: #4670ff;
  margin-right: 1.5rem;
  font-weight: 500;

  svg {
    margin-right: .25rem;
  }
}

.verify-by__avatars {
  display: inline-flex;
  margin-right: 1.5rem;
}
.verify-by__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
  margin-left: -15px;
  background-color: #999;
  background-size: cover;
  color: #fff;
  font-size: .9rem;
  transition: margin-left .2s ease;
}

.verify-by__avatars:hover .verify-by__avatar:not(:first-of-type),
.verify-by__avatars:hover .verify-by__avatar--more {
  margin-left: -5px;
}

a.verify-by__btn,
button.verify-by__btn {
  display: flex;
  align-items: center;
  border: 1px solid;
  padding: .75rem;
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.15;
  margin: 0;
  background: none;
  transition: all .3s ease;

  svg {
    margin-right: .5rem;
  }

  &--blue {
    color: #4670ff;
    border-color: #4670ff;

    &:hover {
      background-color: #E3EAFE;
      border-color: #E3EAFE;
    }
  }
  &--red {
    color: #FF5F57;
    border-color:  #FF5F57;

    &:hover {
      background-color: #FEE7E6;
      border-color: #FEE7E6;
    }
  }
  &--gray {
    color: #8C8C8C;
    border-color: #8C8C8C;
  }
}
