.breadcrumb {
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  width: 100%;
  color: #666;
  letter-spacing: -0.02em;
  margin-bottom: 3rem;
}

.breadcrumb a {
  color: #000;
}

.breadcrumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.breadcrumb ul li::before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xIDFsMy41IDMuNUwxIDgiIHN0cm9rZT0iIzAwMCIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  height: 9px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 3px;
  width: 5px;
}

.breadcrumb ul li:first-child::before {
  display: none;
}

.breadcrumb ul li:last-child a {
  font-weight: 600;
}
