.instruments {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @include breakpoint(m) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.instruments__filters {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  text-align: left;
  top: 0;
  transform: translateX(-100vw);
  transition: all 0.25s ease-in-out;
  width: 90vw;
  z-index: 0;
  &.active {
    transform: translateX(0);
  }
  @include breakpoint(m) {
    margin-right: 50px;
    position: relative;
    transform: translateX(0);
    width: 255px;
    &.active {
    }
  }
  @media screen and (min-width: 800px) and (-ms-high-contrast: none) {
    width: 355px;
  }
}

.instruments__overlay {
  bottom: 0;
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(200vw);
  transition: all 0.25s ease-in-out;
  width: 200vw;
  @include breakpoint(m) {
    display: none;
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    pointer-events: all;
  }
}

.instruments__filters-inner {
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  overflow-y: scroll;
  padding: 2rem;
  @include breakpoint(m) {
    height: auto;
    overflow-y: auto;
    padding: 0;
  }
}

.instruments__list {
  position: relative;
  width: 100%;
  z-index: 0;
  @include breakpoint(m) {
    flex-grow: 3;
    width: min-content;
  }
}

.instruments__no-instruments {
  display: block;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

@media (min-width: 800px) {
  .instruments__no-instruments {
    align-items: center;
    display: flex;
    font-size: 22px;
    height: 100%;
    justify-content: center;
    line-height: 32px;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.instruments__no-instruments-container {
  margin: 25px auto 0;
  max-width: 400px;
}

.instruments__no-instruments-container::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAzNSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBIMjcuMDY2N1YyMS4yNjY3SDBWMFpNMzIuODY2NCAyOUgzNC43OTk4VjcuNzMzMzJIMzAuOTMzMVYyNS4xMzM0SDUuNzk5NzNWMjlIMzIuODY2NFYyOVoiIGZpbGw9IiMxMTExMTEiLz4KPC9zdmc+Cg==");
  background-size: 30px;
  content: "";
  display: block;
  height: 25px;
  margin: 0 auto 15px auto;
  width: 30px;
}

@media (min-width: 800px) {
  .instruments__no-instruments-container::before {
    background-size: 35px;
    height: 29px;
    width: 35px;
  }
}

.instruments__no-instruments-text {
  margin-bottom: 25px;
}

.instruments__no-instruments-reset {
  border: 1px solid black;
  border-radius: 6px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  min-width: 140px;
  outline: none;
  padding: 12px;
  text-align: center;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
  }
}

@media (min-width: 800px) {
  .instruments__no-instruments-reset {
    background-color: #4670ff;
    border: none;
    color: white;

    &:hover {
      background-color: #365bd9;
      border: none;
      color: white;
    }
  }
}
