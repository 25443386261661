.box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 5px 15px 46px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: rem(740);
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
    min-height: 400px;
  }
}

.box__item {
  padding: 40px;
  position: relative;
  text-align: center;
  width: 100%;
}

@media (min-width: 767px) {
  .box__item {
    padding: 68px 0 0;
    width: 50%;
  }
}

@media (min-width: 767px) {
  .box__item:first-child::after {
    background-color: #e4e4e4;
    content: "";
    height: 200px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}

.box h2 {
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.01em;
  max-width: 230px;
  margin: 0 auto rem(15);
}

.box p {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.184px;
  max-width: 230px;
  margin: 0 auto 28px;
}
