.button {
  font-size: 15px;
  display: inline-block;
  line-height: 1;
  background-color: #4670ff;
  color: white;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  border-radius: 4px;
  min-width: 140px;
  &:hover {
    background-color: #365bd9;
  }
}

a.button {
  color: #fff;
}
