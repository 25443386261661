.section {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-align: left;
}

.section--contained {
  max-width: 1200px;
}

.section__inner {
  width: 100%;
  padding: 0 space(xxs);
}

@include breakpoint(m) {
  .section__inner {
    padding: 0 space(s);
    max-width: 1200px;
  }
}

.section__inner--no-padding {
  padding: 0;
}

.section__inner--full-width {
  max-width: 100%;
}

.section--spacing-top-none {
  padding-top: 0;
}

.section--spacing-top-extra-small {
  padding-top: space(xs);
}

.section--spacing-top-small {
  padding-top: space(s);
}

.section--spacing-top-medium {
  padding-top: space(m);
}

.section--spacing-top-large {
  padding-top: space(l);
}

.section--spacing-top-extra-large {
  padding-top: space(xl);
}

.section--spacing-bottom-none {
  padding-bottom: none;
}

.section--spacing-bottom-extra-small {
  padding-bottom: space(xs);
}

.section--spacing-bottom-small {
  padding-bottom: space(s);
}

.section--spacing-bottom-medium {
  padding-bottom: space(m);
}

.section--spacing-bottom-large {
  padding-bottom: space(l);
}

.section--spacing-bottom-extra-large {
  padding-bottom: space(xl);
}
