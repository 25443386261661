.page-list {
  margin-top: 23px;
  text-align: left;
}

@include breakpoint(s) {
  .page-list {
    margin: 89px 0 69px;
  }
}

.page-list__title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 600;
}

.page-list__intro {
  padding: 0 space(xxs);
}

@include breakpoint(m) {
  .page-list__intro {
    padding: 0 space(s);
  }
}

.page-list__intro-inner {
  margin: 0 auto;
  max-width: 640px;
}

@include breakpoint(m) {
  .page-list__title {
    font-size: 28px;
    margin-bottom: 17px;
  }
}

.page-list__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 36px;
  padding: 0;
}

@include breakpoint(s) {
  .page-list__button {
    margin-bottom: 53px;
  }
}

@include breakpoint(m) {
  .page-list__button {
    align-items: flex-end;
    font-size: 18px;
  }
}

.page-list__button::after {
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.5 7L12 1' stroke='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  content: "";
  height: 9px;
  margin-bottom: 0;
  margin-top: 3px;
  margin-left: 5px;
  width: 14px;
}

@include breakpoint(m) {
  .page-list__button::after {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.page-list__button--closed::after {
  transform: rotate(180deg);
}

@include breakpoint(s) {
  .page-list__list {
    padding: 0 space(xxs);
  }
}

@include breakpoint(m) {
  .page-list__list {
    padding: 0 space(s);
  }
}

.page-list__list-inner {
  list-style: none;
  margin: 0 auto 54px;
  max-width: 640px;
  padding: 0;
}

.page-list__list-inner--hidden {
  display: none;
}

@include breakpoint(s) {
  .page-list__list-inner {
    border-bottom: 1px solid #ECEEF1;
    margin-bottom: 0;
    padding-bottom: 51px;
  }
}

.page-list__item {
  border-bottom: 1px solid #ECEEF1;
  padding-bottom: 17px;
  margin-bottom: 12px;
}

@include breakpoint(s) {
  .page-list__item {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 33px;
  }
}

.page-list__item:last-child {
  margin-bottom: 0;
}

.page-list__link {
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 0 space(xxs);
}

@include breakpoint(m) {
  .page-list__link {
    font-size: 18px;
    padding: 0;
  }
}

.page-list__link--icon::before {
  background-color: #F2F2F2;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "";
  height: 40px;
  margin-right: 24px;
  width: 40px;
}

.page-list__link--social-protection-and-gender::before {
  background-image: var(--social-protection-gender);
}

.page-list__link--environment-and-natural-resources::before {
  background-image: var(--environment-and-natural-resources);
}

.page-list__link--markets-and-prices::before {
  background-image: var(--markets-and-prices);
}

.page-list__link--education-and-training-research-and-innovation::before {
  background-image: var(--education-and-training-research-and-innovation);
}

.page-list__link--regional-planning-and-rural-areas::before {
  background-image: var(--regional-planning-and-rural-areas);
}

.page-list__link--trade::before {
  background-image: var(--trade);
}

.page-list__link--agri-food-sector-on-and-off-farm::before {
  background-image: var(--agri-food-sector-on-and-off-farm);
}

.page-list__link--health-quality-standards-and-animal-welfare::before {
  background-image: var(--health-quality-standards-and-animal-welfare);
}

.page-list__link::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.10007 16.0001C8.95007 16.0001 8.79007 15.9401 8.68007 15.8201C8.45007 15.5901 8.45007 15.2101 8.68007 14.9701L20.4501 3.2H16.9501C16.6201 3.2 16.3501 2.93 16.3501 2.6C16.3501 2.27 16.6201 2 16.9501 2H21.8466C22.0177 1.98469 22.1958 2.04138 22.3301 2.17006C22.4714 2.30551 22.526 2.49299 22.5001 2.67326V7.55C22.5001 7.88 22.2301 8.15 21.9001 8.15C21.5701 8.15 21.3001 7.88 21.3001 7.55V4.05003L9.53007 15.8201C9.41007 15.9401 9.25007 16.0001 9.10007 16.0001ZM18.3 21.4V9.88L19.5 8.68V21.4C19.5 22.28 18.78 23 17.9 23H3.1C2.22 23 1.5 22.28 1.5 21.4V6.6C1.5 5.72 2.22 5 3.1 5H15.82L14.62 6.2H3.1C2.88 6.2 2.7 6.38 2.7 6.6V21.4C2.7 21.62 2.88 21.8 3.1 21.8H17.9C18.12 21.8 18.3 21.62 18.3 21.4Z' fill='%238C8C8C'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  content: "";
  height: 24px;
  margin-left: auto;
  width: 24px;
}