.instrument-meta-information {
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: space(xxs) 0.5rem;
  &:first-child {
    border-top: 1px solid #eee;
  }
  &:last-child {
    border-bottom: none;
  }
}

.instrument-meta-information:first-of-type {
  border-top: 1px solid #eee;
}

.instrument-meta-information__title {
  position: relative;
  font-size: 17px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  padding-right: 28px;
  &:hover {
    color: #666;
  }

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYuNjY2NyAxMy4zMzM0TDEwLjAwMDEgNi42NjY3MUwzLjMzMzQyIDEzLjMzMzQiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS40OTk4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
    background-position: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 0;
  }
  &.active:after {
    transform: translateY(-50%);
  }
}

.instrument-meta-information__download {
  font-size: 17px;
  line-height: 1;
  background-color: #4670ff;
  color: white;
  cursor: pointer;
  display: block;
  padding: 1em;
  text-align: center;
  font-weight: 600;
  border-radius: 6px;
  margin-top: 2rem;
  &:hover {
    background-color: #365bd9;
  }
  &:active {
    color: white;
  }
  @media print {
    display: none;
  }
}

.instrument-meta-information__content {
  display: none;
  visibility: hidden;
  max-height: 0;

  @media print {
    display: block;
    visibility: visible;
    max-height: 100%;
    padding-top: 1rem;
  }
}

.instrument-meta-information__title.active
  + .instrument-meta-information__content {
  display: block;
  visibility: visible;
  max-height: 100%;
  padding-top: 1rem;
}

.instrument-meta-information__sdg {
  background-size: 100%;
  display: none;
  vertical-align: top;
  height: 80px;
  width: 80px;
  margin-right: 0;
  margin-bottom: 16px;

  @media print {
    -webkit-print-color-adjust: exact;
    display: inline-block;
  }
}

@media (min-width: 1060px) {
  .instrument-meta-information__sdg {
    margin-right: 6px;
  }

  .instrument-meta-information__sdg:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.instrument-meta-information__sdg-link {
  display: block;
  height: inherit;
  width: inherit;
}

.instrument-meta-information__title.active
  + .instrument-meta-information__content
  .instrument-meta-information__sdg {
  display: inline-block;
}

.instrument-meta-information__sdg-word {
  display: none;
}

.instrument-meta-information__sdg--nopoverty {
  background-image: url("../images/E Inverted Icons_WEB-01.png");
}

.instrument-meta-information__sdg--zerohunger {
  background-image: url("../images/E Inverted Icons_WEB-02.png");
}

.instrument-meta-information__sdg--goodhealthandwellbeing {
  background-image: url("../images/E Inverted Icons_WEB-03.png");
}

.instrument-meta-information__sdg--qualityeducation {
  background-image: url("../images/E Inverted Icons_WEB-04.png");
}

.instrument-meta-information__sdg--genderequality {
  background-image: url("../images/E Inverted Icons_WEB-05.png");
}

.instrument-meta-information__sdg--cleanwaterandsanitation {
  background-image: url("../images/E Inverted Icons_WEB-06.png");
}

.instrument-meta-information__sdg--affordableandcleanenergy {
  background-image: url("../images/E Inverted Icons_WEB-07.png");
}

.instrument-meta-information__sdg--decentworkandeconomicgrowth {
  background-image: url("../images/E Inverted Icons_WEB-08.png");
}

.instrument-meta-information__sdg--industryinnovationandinfrastructure {
  background-image: url("../images/E Inverted Icons_WEB-09.png");
}

.instrument-meta-information__sdg--reducedinequalitites {
  background-image: url("../images/E Inverted Icons_WEB-10.png");
}

.instrument-meta-information__sdg--sustainablecititesandcommunities {
  background-image: url("../images/E Inverted Icons_WEB-11.png");
}

.instrument-meta-information__sdg--responsibleproductionandconsumption {
  background-image: url("../images/E Inverted Icons_WEB-12.png");
}

.instrument-meta-information__sdg--climateaction {
  background-image: url("../images/E Inverted Icons_WEB-13.png");
}

.instrument-meta-information__sdg--lifebelowwater {
  background-image: url("../images/E Inverted Icons_WEB-14.png");
}

.instrument-meta-information__sdg--lifeonland {
  background-image: url("../images/E Inverted Icons_WEB-15.png");
}

.instrument-meta-information__sdg--peacejusticeandstronginstitutions {
  background-image: url("../images/E Inverted Icons_WEB-16.png");
}

.instrument-meta-information__sdg--partnershipsforthegoals {
  background-image: url("../images/E Inverted Icons_WEB-17.png");
}
