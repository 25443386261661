.dimensions nav {
  overflow-x: scroll;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

@media (min-width: 800px) {
  .dimensions nav {
    overflow-x: hidden;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}


.dimensions ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 1000px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
}

@media (min-width: 800px) {
  .dimensions ul {
    width: 100%;
    justify-content: center;
  }
}

.dimensions li {
  display: inline-block;
  margin-bottom: 9px;
  margin-right: 6px;
}

@media (min-width: 800px) {
  .dimensions li {
    margin-right: 3px;
    margin-left: 3px;
  }
}

.dimensions__dimension {
  background: #fff;
  border: 1px solid #000000;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 1;
  padding: 0.4em 1em;
}

.dimensions__dimension:hover {
  background-color: #000;
  color: #fff;
}

.dimensions__dimension--active {
  background-color: #000;
  color: #fff !important;
}
