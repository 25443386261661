.instrument {
  text-align: left;
}

.instrument__content {
  margin-bottom: 2rem;
}

@include breakpoint(m) {
  .instrument {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .instrument__content {
    max-width: 675px;
    padding-right: 40px;
  }

  .instrument__sidebar {
    margin-bottom: 2rem;
    width: 285px;
  }
}

.instrument__kicker {
  @include font-size(m);
  color: #8c8c8c;
  line-height: 1;

  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAuMDA5NTIxNDggMEg5LjQzMTc0VjcuMzMzMzNIMC4wMDk1MjE0OFYwWk0xMC43Nzc4IDkuOTk5OTZWMTBIMTIuMTIzOFYyLjY2NjY3SDEwLjc3NzhWOC42NjY2M0gyLjAyODU3VjkuOTk5OTZIMTAuNzc3OFoiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuNSIvPjwvc3ZnPg==);
    content: "";
    display: inline-block;
    height: 10px;
    margin-right: 6px;
    transform: translateY(-1px);
    width: 12px;

    @media print {
      display: none;
    }
  }
}

.instrument__title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 0.5rem;
}

.instrument__last-updated {
  color: #8c8c8c;
  font-size: 15px;
  line-height: 30px;
}
