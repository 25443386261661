.objective-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: -0.5rem;
  @include breakpoint(s) {
    display: flex;
    flex-wrap: wrap;
  }
}

.objective-list__objective {
  margin-bottom: 1rem;
  @include breakpoint(s) {
    margin-bottom: 0;
    width: 50%;
    padding: 0.5em;
  }
  @include breakpoint(m) {
    width: 25%;
    padding: 0.5em;
  }
}
