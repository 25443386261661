.card {
  background-color: #121a2f;
  border-radius: 6px;
  box-shadow: 8px 8px 14px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding-top: 66.66%;
  position: relative;
  width: 100%;
}

@media (min-width: 800px) {
  .card {
    padding-top: 100%;
  }
}

.card a {
  color: #fff;
}

.card__wrapper {
  align-items: flex-end;
  display: flex;
  height: 100%;
  left: 0;
  padding: 24px;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
}

.card__inner {
  word-wrap: break-word;
  width: 100%;
}

.card__kicker {
  font-size: 15px;
  margin-bottom: 6px;
}

.card__count::before,
.card__kicker--icon::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIG9wYWNpdHk9Ii44IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMGg3djUuNUgwVjB6bTggNy41aDFWMkg4djQuNUgxLjV2MUg4eiIgZmlsbD0iI0Q4RDhEOCIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 10px;
  margin-right: 2px;
  width: 10px;
}

@media print {
  .card__count::before,
  .card__kicker--icon::before {
    display: none;
  }
}

.card__title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 800px) {
  .card__title {
    font-size: 1.54vw;
    line-height: 1.2;
  }
}

@media (min-width: 1440px) {
  .card__title {
    font-size: 22px;
  }
}

.card__count {
  font-size: 13px;
  margin-top: 8px;
}

.card--large .card__wrapper {
  padding: 20px;
}

@media (min-width: 800px) {
  .card--large .card__wrapper {
    padding: 32px;
  }
}

.card--large .card__kicker {
  font-size: 15px;
  line-height: 1.4;
}

.card--large .card__title {
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 8px;
}

@media (min-width: 1120px) {
  .card--large .card__title {
    font-size: 1.95vw;
    line-height: 1.2;
  }
}

@media (min-width: 1440px) {
  .card--large .card__title {
    font-size: 28px;
    line-height: 1.2;
  }
}

.card--large .card__count {
  font-size: 15px;
  line-height: 1.4;
}

.card--large .card__count::before {
  background-size: 100%;
  height: 10px;
  margin-right: 3px;
  width: 12px;
}
