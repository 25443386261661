@function rem($size) {
  $remSize: $size / 16;

  @return #{$remSize}rem;
}

@function font-size($key) {
  @return map-get($font-sizes, $key);
}

@function matched-line-height($key) {
  @return map-get($matched-line-heights, $key);
}

@mixin font-size($size) {
  font-size: rem(font-size($size));
  line-height: matched-line-height($size);
}
