@function rem($size) {
  $remSize: $size / 16;

  @return #{$remSize}rem;
}

@function font-size($key) {
  @return map-get($font-sizes, $key);
}

@function matched-line-height($key) {
  @return map-get($matched-line-heights, $key);
}

@function space($key) {
  @return map-get($spaces, $key);
}

// Breakpoints

 @mixin breakpoint($point) {
   @if $point == xl {
     @media (min-width: 1440px) { @content ; }
  }

   @else if $point == l {
     @media (min-width: 1280px) { @content ; }
  }

    @else if $point == m {
     @media (min-width: 800px) { @content ; }
  }

   @else if $point == s {
     @media (min-width: 600px)  { @content ; }
  }

  @else if $point == xs {
     @media (max-width: 480px)  { @content ; }

  }
}
