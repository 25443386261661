.instrument-search {
  padding: space(xxs) 0;
  position: relative;
  text-align: right;
  z-index: 1;
  @include breakpoint(m) {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
  }
}

.instrument-search input {
  background: #f6f6f6;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjYxNzUyIDEwLjAzMTdDNy43MzE0OCAxMC42NDI0IDYuNjU3NSAxMSA1LjUgMTFDMi40NjI0MyAxMSAwIDguNTM3NTcgMCA1LjVDMCAyLjQ2MjQzIDIuNDYyNDMgMCA1LjUgMEM4LjUzNzU3IDAgMTEgMi40NjI0MyAxMSA1LjVDMTEgNi42NTc1IDEwLjY0MjQgNy43MzE0OCAxMC4wMzE3IDguNjE3NTNMMTQuMzY0IDEyLjk0OTdMMTIuOTQ5NyAxNC4zNjRMOC42MTc1MiAxMC4wMzE3Wk05IDUuNUM5IDcuNDMzIDcuNDMzIDkgNS41IDlDMy41NjcgOSAyIDcuNDMzIDIgNS41QzIgMy41NjcgMy41NjcgMiA1LjUgMkM3LjQzMyAyIDkgMy41NjcgOSA1LjVaIiBmaWxsPSIjOEM4QzhDIi8+Cjwvc3ZnPgo=");
  background-position: 0.8em 45%;
  background-repeat: no-repeat;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  padding: 0.75em 35px;
  position: relative;
  transition: width 0.15s ease-in-out;
  z-index: 0;
  width: 100%;
  @include breakpoint(m) {
    width: 275px;
    font-size: 15px;
    background-position: 0.8em 53%;
  }
}

.instrument-search input::-webkit-input-placeholder {
  color: #8c8c8c;
}

.instrument-search input::-moz-placeholder {
  color: #8c8c8c;
}

.instrument-search input:-ms-input-placeholder {
  color: #8c8c8c;
}

.instrument-search input:-moz-placeholder {
  color: #8c8c8c;
}

.instrument-search input:focus {
  width: 100%;
}

.instrument-search__cancel-wrapper {
  position: relative;

  @include breakpoint(m) {
    display: block;
    min-width: 345px;
  }
}

.instrument-search__cancel {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTEgMTIuMzgwOUwxNS42MTkxIDE3TDE3IDE1LjYxOTFMMTIuMzgwOSAxMUwxNyA2LjM4MDlMMTUuNjE5MSA1TDExIDkuNjE5MUw2LjM4MDkgNUw1IDYuMzgwOUw5LjYxOTEgMTFMNSAxNS42MTkxTDYuMzgwOSAxN0wxMSAxMi4zODA5WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
  cursor: pointer;
  height: 22px;
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  z-index: 1;
}
