.policy-dimensions-header {
  text-align: left;
  @include breakpoint(s) {
    text-align: center;
  }
}

.policy-dimensions-header__text {
  font-size: 17px;
  line-height: 28px;
  max-width: 550px;
  margin: 1rem auto 30px auto;
}

.policy-dimensions-header__dimensions {
  margin-bottom: 40px;
}
