.instrument-filters {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  padding: space(xxs) 0;
  text-align: left;
}

.instrument-filters__header {
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding-bottom: space(xxs);
  text-align: left;
  width: 100%;
}

.instrument-filters__title {
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.3;
}

.instrument-filters__toggle {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMTgxOTMgMS44MTgyNEM3LjY3OTg1IDEuODE4MjQgNy4yNzI4NCAyLjIyNTI1IDcuMjcyODQgMi43MjczM1YzLjYzNjQzSDEuODE4MzZWNS40NTQ2MUg3LjI3Mjg0VjYuMzYzNjlDNy4yNzI4NCA2Ljg2NTc3IDcuNjc5ODUgNy4yNzI3OCA4LjE4MTkzIDcuMjcyNzhDOC42ODQwMSA3LjI3Mjc4IDkuMDkxMDIgNi44NjU3NyA5LjA5MTAyIDYuMzYzNjlWNS40NTQ2MUgxOC4xODJWMy42MzY0M0g5LjA5MTAyVjIuNzI3MzNDOS4wOTEwMiAyLjIyNTI1IDguNjg0MDEgMS44MTgyNCA4LjE4MTkzIDEuODE4MjRaTTE4LjE4MiA5LjA5MDlIMTUuNDU0OFY4LjE4MTlDMTUuNDU0OCA3LjY3OTgyIDE1LjA0NzggNy4yNzI4MSAxNC41NDU3IDcuMjcyODFDMTQuMDQzNiA3LjI3MjgxIDEzLjYzNjYgNy42Nzk4MiAxMy42MzY2IDguMTgxOVY5LjA5MDlIMS44MTgzNlYxMC45MDkxSDEzLjYzNjZWMTEuODE4M0MxMy42MzY2IDEyLjMyMDMgMTQuMDQzNiAxMi43Mjc0IDE0LjU0NTcgMTIuNzI3NEMxNS4wNDc4IDEyLjcyNzQgMTUuNDU0OCAxMi4zMjAzIDE1LjQ1NDggMTEuODE4M1YxMC45MDkxSDE4LjE4MlY5LjA5MDlaTTQuNTQ1NjEgMTQuNTQ1NUgxLjgxODM2VjE2LjM2MzdINC41NDU2MVYxNy4yNzI3QzQuNTQ1NjEgMTcuNzc0OCA0Ljk1MjYyIDE4LjE4MTggNS40NTQ3IDE4LjE4MThDNS45NTY3OCAxOC4xODE4IDYuMzYzNzkgMTcuNzc0OCA2LjM2Mzc5IDE3LjI3MjdWMTYuMzYzN0gxOC4xODJWMTQuNTQ1NUg2LjM2Mzc5VjEzLjYzNjRDNi4zNjM3OSAxMy4xMzQzIDUuOTU2NzggMTIuNzI3MyA1LjQ1NDcgMTIuNzI3M0M0Ljk1MjYyIDEyLjcyNzMgNC41NDU2MSAxMy4xMzQzIDQuNTQ1NjEgMTMuNjM2NFYxNC41NDU1WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
  @include breakpoint(m) {
    display: none;
  }
}

.instrument-filters__close {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2IDE4LjA2Nkw0Ljk3NTE1IDI5LjA5MDlMMi45MDkxMyAyNy4wMjQ5TDEzLjkzNCAxNkwyLjkwOTEyIDQuOTc1MDhMNC45NzUxMyAyLjkwOTA3TDE2IDEzLjkzNEwyNy4wMjQ5IDIuOTA5MDZMMjkuMDkxIDQuOTc1MDdMMTguMDY2IDE2TDI5LjA5MDkgMjcuMDI0OUwyNy4wMjQ5IDI5LjA5MDlMMTYgMTguMDY2WiIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  display: block;
  height: 18px;
  transform: translateX(-1 px);
  width: 18px;
  @include breakpoint(m) {
    display: none;
  }
}

.instrument-filters__result-count {
  color: silver;
}

.instrument-filters__filter {
  border-bottom: 1px solid #eee;
  padding: space(xxs) 0;
  width: 100%;
}

.instrument-filters__filter-title {
  position: relative;
  @include font-size(m);
  cursor: pointer;
  font-weight: 600;
  user-select: none;
  &:hover {
    color: #666;
  }

  &:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYuNjY2NyAxMy4zMzM0TDEwLjAwMDEgNi42NjY3MUwzLjMzMzQyIDEzLjMzMzQiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS40OTk4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=);
    background-position: center;
    content: "";
    display: block;
    height: 20px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 20px;
  }
  &.active:after {
    transform: translateY(-50%);
  }
}

.instrument-filters__filter-options {
  margin-top: space(xxs);
}

.instrument-filters__filter-option {
  align-items: center;
  display: flex;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.instrument-filters__filter-option input[type="checkbox"] {
  display: none;
}

.instrument-filters__filter-option label {
  cursor: pointer;
  padding-left: 30px;
  position: relative;
  user-select: none;
  width: 90%;
}

.instrument-filters__filter-option input[type="checkbox"] + label:before {
  border: 1px solid #000;
  border-radius: 0.2em;
  color: transparent;
  content: "";
  display: inline-block;
  height: 1.3em;
  left: 0;
  line-height: 0.8;
  padding-bottom: 0.3em;
  padding-left: 0.2em;
  position: absolute;
  top: 0;
  transform: translateY(-1px);
  transition: 0.2s;
  vertical-align: middle;
  width: 1.3em;
}

.instrument-filters__filter-option input[type="checkbox"] + label:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEuNzA3MDYgMi41NDgyNkwwLjI5Mjg0NyAzLjk2MjQ3TDUuMTMxMiA4LjgwMDgzTDEyLjIyNSAxLjcwNzA2TDEwLjgxMDggMC4yOTI4NDdMNS4xMzEyIDUuOTcyNEwxLjcwNzA2IDIuNTQ4MjZaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==);
  content: "";
  height: 9px;
  left: 4px;
  position: absolute;
  top: 5px;
  width: 12px;
}

.instrument-filters__filter-option
  input[type="checkbox"]
  + label:active:before {
  transform: scale(0);
}

.instrument-filters__filter-option
  input[type="checkbox"]:checked
  + label:before {
  background-color: #4670ff;
  border-color: #4670ff;
  color: #fff;
}

.instrument-filters__filter-option
  input[type="checkbox"]:disabled
  + label:before {
  border-color: #aaa;
  transform: scale(1);
}

.instrument-filters__filter-option
  input[type="checkbox"]:checked:disabled
  + label:before {
  background-color: #bfb;
  border-color: #bfb;
  transform: scale(1);
}
