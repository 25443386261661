html {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: white;
  overflow-x: hidden;
}

@include breakpoint(m) {
  html {
    font-size: 16px;
  }
}

main {
  text-align: center;
}

// img {
//   max-width: 100%;
//   filter: grayscale(100%);
//   transition: filter 250ms ease-in-out;
//   &:hover {
//     filter: grayscale(0%);
//   }
// }

a,
a:link,
a:visited,
a:active {
  color: black;
  text-decoration: none;
}

input[type="submit"] {
  cursor: pointer;
}
