.instrument-cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  margin: -0.5rem;
}

.instrument-cards__instrument {
  width: 100%;
  padding: 0.5rem;
  @include breakpoint(s) {
    width: 50%;
  }
  @include breakpoint(l) {
    width: 33.3%;
  }
}
