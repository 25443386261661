.page-cta {
  max-width: 640px;
  margin: 0 auto;
  padding-top: 20px;
  text-align: left;
}

@include breakpoint(m) {
  .page-cta {
    margin-bottom: 130px;
  }
}

.page-cta__name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 0;
  position: relative;
}

@include breakpoint(m) {
  .page-cta__name {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 40px;
  }
}

@media (min-width: 850px) {
  .page-cta__name::before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45px;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    top: -5px;
    left: -70px;
    position: absolute;
    width: 45px;
  }
}

.page-cta__name--social-protection-and-gender::before {
  background-image: var(--social-protection-gender);
}

.page-cta__name--environment-and-natural-resources::before {
  background-image: var(--environment-and-natural-resources);
}

.page-cta__name--markets-and-prices::before {
  background-image: var(--markets-and-prices);
}

.page-cta__name--education-and-training-research-and-innovation::before {
  background-image: var(--education-and-training-research-and-innovation);
}

.page-cta__name--trade::before {
  background-image: var(--trade);
}

.page-cta__name--regional-planning-and-rural-areas::before {
  background-image: var(--regional-planning-and-rural-areas);
}

.page-cta__name--agri-food-sector-on-and-off-farm::before {
  background-image: var(--agri-food-sector-on-and-off-farm);
}

.page-cta__name--health-quality-standards-and-animal-welfare::before {
  background-image: var(--health-quality-standards-and-animal-welfare);
}