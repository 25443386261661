.cookiebar {
  display: none;
}

.cookiebar--active {
  background-color: #121a2f;
  bottom: 0;
  color: white;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  padding: 26px 1.25rem;
  position: fixed;
  text-align: left;
  width: 100%;
  z-index: 100;
}

@media (min-width: 600px) {
  .cookiebar--active {
    padding: 17px;
  }
}

.cookiebar__container {
  margin: 0 auto;
  max-width: 1200px;
  position: relative;
}

@media (min-width: 600px) {
  .cookiebar__container {
    align-items: center;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding: 0 2.8rem;
  }
}

.cookiebar__text {
  position: relative;
  margin-bottom: 20px;
  max-width: 350px;
  padding-left: 30px;
}

.cookiebar__text::before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIyIiB5PSIzIiB3aWR0aD0iMTgiIGhlaWdodD0iMTgiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEyLjIgNy45MDAzOUMxMi4yIDguNTA3OSAxMS43MDc1IDkuMDAwMzkgMTEuMSA5LjAwMDM5QzEwLjQ5MjUgOS4wMDAzOSA5Ljk5OTk5IDguNTA3OSA5Ljk5OTk5IDcuOTAwMzlDOS45OTk5OSA3LjI5Mjg4IDEwLjQ5MjUgNi44MDAzOSAxMS4xIDYuODAwMzlDMTEuNzA3NSA2LjgwMDM5IDEyLjIgNy4yOTI4OCAxMi4yIDcuOTAwMzlaTTEyIDEwLjAwMDZIMTAuNFYxNi40MDA0SDkuNTk5OTlWMTYuODAwNEgxMC40VjE2LjgwMDZIMTJWMTYuODAwNEgxMi44VjE2LjQwMDRIMTJWMTAuMDAwNlpNMTAuNCAxMC40MDA0VjEwLjAwMDRIOS41OTk5OVYxMC40MDA0SDEwLjRaTTExLjIgMjAuMDAwMlYyMC4wMDAyQzE1LjYxODIgMjAuMDAwMiAxOS4yIDE2LjQxODUgMTkuMiAxMi4wMDAyQzE5LjIgNy41ODE5MiAxNS42MTgyIDQuMDAwMiAxMS4yIDQuMDAwMkM2Ljc4MTY5IDQuMDAwMiAzLjE5OTk2IDcuNTgxOTIgMy4xOTk5NiAxMi4wMDAyQzMuMTk5OTYgMTYuNDE4NSA2Ljc4MTY5IDIwLjAwMDIgMTEuMiAyMC4wMDAyVjIwLjAwMDJaTTExLjIgMjAuODAyN1YyMC44MDI3QzYuMzM5ODYgMjAuODAyNyAyLjM5OTk2IDE2Ljg2MjIgMi4zOTk5NiAxMi4wMDE0QzIuMzk5OTYgNy4xNDA2NSA2LjMzOTg2IDMuMjAwMiAxMS4yIDMuMjAwMkMxNi4wNjAxIDMuMjAwMiAyMCA3LjE0MDY1IDIwIDEyLjAwMTRDMjAgMTYuODYyMiAxNi4wNjAxIDIwLjgwMjcgMTEuMiAyMC44MDI3VjIwLjgwMjdaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazApIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDBIMjIuNFYyNEgwVjBaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+Cjwvc3ZnPgo=");
  background-size: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
  top: 1px;
}

@media (min-width: 600px) {
  .cookiebar__text {
    grid-column: span 2;
    margin-bottom: 0;
    max-width: none;
  }
}

.cookiebar__buttons {
  grid-column: span 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

@media (min-width: 600px) {
  .cookiebar__buttons {
    justify-content: flex-end;
    padding-left: 0;
  }
}

.cookiebar__button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  outline: none;
  padding: 10px 18px;
}

.cookiebar__button--close {
  position: relative;
  padding: 10px 24px;
  border: none;
}

.cookiebar__button:hover {
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}

.cookiebar__button--close:hover {
  border: none;
}

.cookiebar__button--close::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDguMTA0NzRMMTIuODk1MyAxNEwxNCAxMi44OTUzTDguMTA0NzQgN0wxNCAxLjEwNDc0TDEyLjg5NTMgMEw3IDUuODk1MjZMMS4xMDQ3NCAwTDAgMS4xMDQ3NEw1Ljg5NTI2IDdMMCAxMi44OTUzTDEuMTA0NzQgMTRMNyA4LjEwNDc0WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
  background-size: 14px;
  content: "";
  display: block;
  height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-47%);
  width: 14px;
}

.cookiebar__close:hover {
  color: rgba(255, 255, 255, 0.8);
}

.cookiebar__close:hover::after {
  opacity: 0.8;
}
