.masthead__section {
  height: 523px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: left;
}

.masthead__section-inner {
  padding: 0 1.5rem;
  width: 100%;
}

@media (min-width: 800px) {
  .masthead__section-inner {
    max-width: 1200px;
    padding: 0 2.8rem;
  }
}

.masthead__banner {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  text-align: left;
  height: 600px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.masthead__banner__image {
  display: inline-block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.masthead__banner::after {
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0.5) 100%,
    transparent 100%
  );
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.masthead__intro {
  color: #fff;
  margin: -55px 2.8rem 0;
  text-align: center;
}

.masthead__kicker {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 25px;
  margin-bottom: 5px;
}

.masthead__headline {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

@media (min-width: 800px) {
  .masthead__headline {
    font-size: 2.25rem;
    line-height: 1.1;
  }
}

@media (min-width: 1280px) {
  .masthead__headline {
    font-size: 4.5rem;
  }
}

.masthead__description {
  font-size: 16px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  max-width: 33.75rem;
}
