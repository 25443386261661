.centered-text {
  text-align: left;
  margin-top: 55px;
}

.centered-text__content {
  margin: 0 auto 0;
}

@include breakpoint(m) {
  .centered-text {
    display: flex;
    justify-content: space-between;
    margin-top: 116px;
  }

  .centered-text__content {
    max-width: 675px;
    margin-bottom: 2rem;
    padding-right: 40px;
  }
}
