

.profile__box {
  text-align: left;
  max-width: 38rem;
  padding: 4.5rem 2rem;
  margin: 10rem auto 4rem;
  background: #fff;
  box-shadow: 5px 15px 46px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media (min-width: 767px) {
  .profile__box {
    padding: 4.5rem 7rem;
  }
}

.profile__title {
  text-align: center;
  margin-bottom: 3rem;
}

.profile__signout {
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 400;
  text-align: center;

  a {
    color: #737373;
  }
}

.profile__avatar {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 0 auto 3rem;
  background-size: cover;
}

.profile__alerts {
  list-style: none;
  color: #FF5F57;
  padding: 0;
  font-size: .9rem;
  margin-bottom: 1.5rem;

  a {
    color: #FF5F57
  }
}

.profile__success {
  color: #4670ff;
  font-size: .9rem;
  margin-bottom: 1.5rem;

  a {
    color: #4670ff
  }
}

.profile {
  text-align: left;

  fieldset {
    border: none;
    padding: 0;
  }

  p {
    margin-bottom: 1rem;
  }
}

.profile label {
  display: block;
  margin-bottom: .5rem;
  font-size: .9rem;
  font-weight: 500;
  color: #737373;

  &.profile__filepicker {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    span {
      margin-left: .25rem;
    }
  }
}

.profile input {
  width: 100%;
  border: none;
  font-size: 1rem;
  line-height: 1.5;

  &:focus {
    outline: none;
  }

  &[type="email"],
  &[type="password"],
  &[type="url"] {
    background: #F2F2F2;
    border-radius: 4px;
    padding: .75rem 1rem;
  }

  &[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus + .profile__filerpicker {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

  &[type="submit"] {
    margin-top: 1.5rem;
    background: #4670FF;
    border-radius: 6px;
    color: #fff;
    padding: .875rem;
    font-weight: 500;

    &:hover {
      background-color: #365bd9;
    }
  }
}

.profile__instruments {
  padding-left: 1.25rem;
  color: #737373;

  li {
    margin-bottom: .25rem;
  }

  a {
    color: #737373;
  }
}
