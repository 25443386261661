.objective-overview {
  @include breakpoint(s) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.objective-overview__card {
  width: 100%;
  margin-bottom: 1rem;
  @include breakpoint(s) {
    width: 300px;
  }
  @include breakpoint(m) {
    width: 350px;
  }
  @media screen and (min-width: 800px) and (-ms-high-contrast: none) {
    width: 650px;
  }
}

.objective-overview__instrument-list {
  @include breakpoint(s) {
    flex-grow: 3;
    padding-left: 35px;
    width: min-content;
  }
}
