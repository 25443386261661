.spacer-headline {
  @include font-size(l);
  position: relative;
  font-weight: 600;
  margin-top: rem(80);
  margin-bottom: 15px;
  letter-spacing: -0.03em;
  text-align: center;
  @include breakpoint(m) {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 35px;
  }
}

.spacer-headline::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px solid #e4e4e4;
  z-index: -1;
}

.spacer-headline span {
  background: #fff;
  padding-left: rem(30);
  padding-right: rem(30);
}
