.text {
}

.text__body {
  @include font-size(s);
}

@include breakpoint(m) {
  .text__body {
    @include font-size(s);
  }

}

@include breakpoint(m) {
  .text__body--two-columns {
    columns: 2;
    column-gap: 4em;
  }

}
