.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.overlay--active {
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  z-index: 100;
}

.overlay__wrapper {
  height: 100%;
  transform: translateX(calc(100% + 100%));
  transition: all 0.2s ease-in-out;
  width: 100%;
}

@media (min-width: 767px) {
  .overlay__wrapper {
    transform: translateX(100%);
  }
}

.overlay--active .overlay__wrapper {
  transform: translateX(0);
}

@media (min-width: 767px) {
  .overlay--active .overlay__wrapper {
    transform: translateX(calc(100% - 450px));
  }
}

.overlay__panel {
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  height: 100%;
  padding: 116px 23px;
  position: relative;
  overflow-y: scroll;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  text-align: left;
  top: 0;
  transition: all 0.15s ease-in-out;
  width: 100%;
}

.overlay__panel::-webkit-scrollbar {
  width: 0;
}

@media (min-width: 767px) {
  .overlay__panel {
    padding: 110px 55px;
    width: 450px;
  }
}

.overlay__close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNCAxNi4wNjZMMi45NzUxNSAyNy4wOTA5TDAuOTA5MTMxIDI1LjAyNDlMMTEuOTM0IDE0TDAuOTA5MTE5IDIuOTc1MDhMMi45NzUxMyAwLjkwOTA2NkwxNCAxMS45MzRMMjUuMDI0OSAwLjkwOTA1OEwyNy4wOTEgMi45NzUwN0wxNi4wNjYgMTRMMjcuMDkwOSAyNS4wMjQ5TDI1LjAyNDkgMjcuMDkwOUwxNCAxNi4wNjZaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K");
  background-size: 100%;
  cursor: pointer;
  height: 26px;
  left: 23px;
  position: absolute;
  top: 38px;
  transition: opacity 0.15s ease-in-out;
  width: 26px;
}

@media (min-width: 767px) {
  .overlay__close {
    left: auto;
    right: 35px;
    top: 38px;
  }
}

.overlay__close:hover {
  opacity: 0.6;
}

.overlay p,
.overlay ul,
.overlay ol {
  display: none;
}

.overlay p:first-child,
.overlay ul:first-child,
.overlay ol:first-child {
  display: block;
}

a.overlay__button {
  background-color: #4670ff;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 17px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 1em;
  text-align: center;
  max-width: 172px;
}

a.overlay__button:hover {
  background-color: #365bd9;
}

a.overlay__button:active {
  color: #fff;
}

@media print {
  .overlay__button {
    display: none;
  }
}
