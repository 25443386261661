.body-text {
  margin-bottom: 48px;
}

.body-text__title {
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 0.5rem;
  @include breakpoint(s) {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 40px;
    margin-top: 0.5rem;
  }
}

.body-text__description > h1,
.body-text__description > h2,
.body-text__description > h3 {
  margin-bottom: 25px;
}

.body-text__description > h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 16px;
  @include breakpoint(s) {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 16px;
  }

}

.body-text__description > p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 1.6;
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .body-text__description > p {
    font-size: 17px;
    line-height: 27px;
  }
}

.body-text__last-updated {
  color: #8c8c8c;
  font-size: 15px;
  line-height: 30px;
}

.body-text__description ol,
.body-text__description ul {
  font-size: 17px;
  letter-spacing: -0.17px;
  line-height: 27px;
  padding-left: 0;
  position: relative;
  margin-bottom: 33px;
}

.body-text__description ul li {
  list-style: none;
  padding-left: 25px;
}

.body-text__description ul li:before {
  position: absolute;
  display: block;
  content: "";
  width: 16px;
  height: 12px;
  top: 7px;
  left: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjUgMWw1IDUtNSA1TTE1IDZIMSIgc3Ryb2tlPSIjMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=");
}

@media print {
  .body-text__description ul li:before {
    display: none;
  }
}

.body-text__description ol {
  margin-left: 22px;
}

.body-text__description ol li {
  padding-left: 4px;
}

.body-text__description ol li,
.body-text__description ul li {
  margin-bottom: 18px;
  position: relative;
}

.body-text__description blockquote {
  border-left: 2px solid #4670FF;
  font-size: 24px;
  line-height: 31px;
  font-weight: 700;
  margin: 40px 0 40px 28px;
  padding: 0 55px 0 13px;
}

.body-text__description a {
  border-bottom: 1px solid #4670ff;
  color: #4670ff;
  padding-bottom: 2px;
}

.body-text__description .body-text__link {
  display: inline-block;
  border-bottom: none;
  font-weight: 700;
  color: black;
  margin-top: 15px;
}

.body-text__description .body-text__link::after {
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 3.5L1 6' stroke='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-left: 6px;
  height: 7px;
  width: 6px;
}
