.logos {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(50);
  @include breakpoint(s) {
    flex-direction: row;
  }
}

.logos__item {
  max-width: rem(180);
  margin-top: rem(25);
  @include breakpoint(s) {
    max-width: rem(230);
    margin-left: rem(25);
    margin-right: rem(25);
    margin-bottom: 0;
  }
}

.logos__item img {
  width: 100%;
  height: auto;
}
