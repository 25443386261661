.meta-list {
  padding-top: 10px;
}

.meta-list ul {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.2px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.meta-list li {
  margin-bottom: 20px;
}

.meta-list--no-bullets a,
.meta-list--no-bullets li {
  color: grey;
}

.meta-list--arrows li {
  padding-left: 26px;
  position: relative;
}

.meta-list li:last-child {
  margin-bottom: 0;
}

@media print {
  .meta-list--arrows ul {
    list-style: decimal;
    padding-left: 24px;
  }
}

@media print {
  .meta-list--arrows li {
    padding-left: 0;
  }
}

.meta-list--arrows li::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjUgMWw1IDUtNSA1TTE1IDZIMSIgc3Ryb2tlPSIjMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=");
  content: "";
  display: block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 16px;
}

@media print {
  .meta-list--arrows li::before {
    display: none;
  }
}

.meta-list__trade-distortion {
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.2px;
  vertical-align: middle;
}

.meta-list__trade-distortion::before {
  border-radius: 50%;
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  margin-right: 6px;
  width: 14px;
  transform: translateY(-2px);
}

.meta-list__trade-distortion--no-distortion {
  color: #34c759;
}

.meta-list__trade-distortion--no-distortion::before {
  background-color: #34c759;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOCA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMi43NTEyOCA0Ljc3Mzg1TDEuMDEyNDggMy4wMjgwNUwwLjQyMTY3NyAzLjYxNzQ1TDIuNzUxMjggNS45NTU0NUw3Ljc1MDY4IDAuOTM3ODUzTDcuMTYyNjggMC4zNDk4NTRMMi43NTEyOCA0Ljc3Mzg1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position: calc(53%);
  background-size: 7px;
}

.meta-list__trade-distortion--yes-distortion {
  color: #ff3b30;
}

.meta-list__trade-distortion--yes-distortion::before {
  background-color: #ff3b30;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgNiA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC4yNzg2MjUgMC4yODk4ODZMNS43MjMwNyA1LjcxMTU2IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuNzc2NDYiLz4KPHBhdGggZD0iTTAuMjc4NTAzIDUuNzEwNjZMNS43MjI5NSAwLjI4ODk4OSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjc3NjQ2Ii8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 6px;
}
