.home__banner {
  height: 600px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
  background-position: center;
  background-size: cover;
}

.home__banner__image {
  -o-object-fit: cover;
  display: inline-block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.home__banner:after {
  background: linear-gradient(
    180deg,
    rgba(17, 17, 17, 0.5) 100%,
    transparent 0
  );
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

.home__intro {
  color: #fff;
  margin: 24px auto 3.125rem;
  padding: 0 0.8rem;
  text-align: center;
}

@media (min-width: 767px) {
  .home__intro {
    margin-top: 48px;
    padding: 0;
    max-width: 620px;
    padding: 0 2rem;
  }
}


@media (min-width: 1280px) {
  .home__intro {
    max-width: 1020px;
    padding: 0 2.8rem;
  }
}

.home__intro h1 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -0.184px;
  line-height: 44px;
  margin-bottom: 13px;
}

@media (min-width: 767px) {
  .home__intro h1 {
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1280px) {
  .home__intro h1 {
    font-size: 4.5rem;
  }
}

.home__intro p {
  font-size: 16px;
  line-height: 26px;
  margin-left: auto;
  margin-right: auto;
  max-width: 33.75rem;
}

.home__intro p:first-child {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 25px;
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .home__intro p:first-child {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

@media (min-width: 1280px) {
  .home__intro p:first-child {
    margin-bottom: 4px;
  }
}
