p {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

h1 {
  font-size: 30px;
  margin: 0;
}

h2 {
  margin: 0;
}
