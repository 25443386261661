.headline {
  position: relative;
  text-align: center;
  margin-bottom: 3em;

  &__headline {
    position: relative;
  }

  &__kicker {
    font-size: 14px;
    display: inline-block;
    background-color: rgba(255,255,255,0.125);
    color: #000;
    border-radius: 2rem;
    padding: 0.25rem 1rem;
    margin-bottom: 0.25rem;
  }

  &__copy {
    @include font-size(l);

    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 0 2rem;
    z-index: 1;
  }

  &__rule {
    @include breakpoint(s) {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: black;
      top: 50%;
      transform: translateY(-2px);
      z-index: 0;
    }
  }

  &--left {
    text-align: left;

    & .headline__copy {
      padding-left: 0
    }
  }

  &--right {
    text-align: right;

    & .headline__copy {
      padding-right: 0
    }
  }

  &--center {
    text-align: center;

  }

}
